<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Admin functions</h3>

	<p>Depending on the <span v-html="link('satchel_plans', 'Satchel Publishing Option')"></span> your organization has subscribed to, you will notice different options for managing users and their levels of admin access. </p>

	<p><span v-html="link('roles_framework', 'Framework level roles')"></span> roles are available to organizations subscribing to the Shared Hosting and Dedicated Hosting plans. 
	
		<p>Framework level roles can: 
			<ul>
				<li>Manage framework roles for existing default system level users</li>
				<li><span v-html="link('reviewing', 'Review')"></span> private and public frameworks</li>
				<li><span v-html="link('editing', 'Edit')"></span> private and public frameworks</li>
			</ul>
		</p>
	</p>

	<p><span v-html="link('roles_system', 'System level roles')"></span> are only available to organizations subscribing to the Dedicated Hosting plan. </p>

	<p>The System level roles can:
			<ul>
				<li><span v-html="link('editing', 'Author')"></span> and <span v-html="link('mirrors', 'mirror')"></span> frameworks</li>
				<li>Set up <span v-html="link('public_review_settings', 'Public review settings')"></span></li>
				<li>Customize  how frameworks are organized in the instance <span v-html="link('framework_categories', '(categories)')"></span></li>
				<li><span v-html="link('user_roles_admin', 'Manage users')"></span></li>
				<li>View usage <span v-html="link('access_reports', 'Framework access report')"></span></li>
			</ul>
		</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	